<template>
    <div>
        <el-card>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="add">{{
                    '新增讲师'
                }}</el-button>
            </nav>
        </el-card>

        <el-card style="margin-top: 10px">
            <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
                @sort-change="sortChange"
            >
                <el-table-column label="ID" prop="id"> </el-table-column>
                <el-table-column label="姓名" prop="realName">
                </el-table-column>
                <el-table-column label="图片">
                    <template #default="{ row }">
                        <p-image
                            :auto="true"
                            :src="$store.getters.imgCol + row.avatar"
                        ></p-image>
                    </template>
                </el-table-column>
                <el-table-column label="公司">
                    <template #default="{ row }">
                        {{ row.company }}
                    </template>
                </el-table-column>
                <el-table-column label="职位" prop="job"></el-table-column>
                <el-table-column
                    label="介绍"
                    prop="desc"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column label="排序" prop="orderNum">
                    <template #default="{ row }">
                        <Remark
                            :row="row"
                            url="/admin/Activity/speakerOrder"
                            rowKey="orderNum"
                            @getlist="getlist"
                        ></Remark>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <el-button
                            @click="edit(row)"
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            style="color: red"
                            @click="del(row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
    </div>
</template>

<script>
import list from '/src/mixin/list.js'
export default {
    mixins: [list],
    data() {
        return {
            search: {
                page: 1,
                pageSize: 10,
            },
        }
    },
    methods: {
        add() {
            this.$router.push('/activity_speaker_edit')
        },
        sortChange(el) {
            //   console.log(el)
            this.getlist()
        },
        del(id) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$http
                        .delete('/admin/Activity/speakerDel', {
                            params: {
                                id: id,
                            },
                        })
                        .then(({ data: res }) => {
                            if (res.errorCode == 200) {
                                this.$message.success(res.message)
                                if (this.total % 10 == 1) {
                                    this.search.page--
                                }
                                this.getlist()
                            }
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        async getlist() {
            let { data } = await this.$http.get(
                '/admin/Activity/getSpeakerList',
                {
                    params: {
                        ...this.search,
                    },
                }
            )
            this.list = data.data.list
            this.total = data.data.totalCount
        },
        edit(row) {
            this.$router.push('/activity_speaker_edit?id=' + row.id)
        },
    },
    created() {
        this.getlist()
    },
}
</script>

<style lang="less" scoped></style>
