var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.add}},[_vm._v(_vm._s('新增讲师'))])],1)]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"ID","prop":"id"}}),_c('el-table-column',{attrs:{"label":"姓名","prop":"realName"}}),_c('el-table-column',{attrs:{"label":"图片"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p-image',{attrs:{"auto":true,"src":_vm.$store.getters.imgCol + row.avatar}})]}}])}),_c('el-table-column',{attrs:{"label":"公司"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.company)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"职位","prop":"job"}}),_c('el-table-column',{attrs:{"label":"介绍","prop":"desc","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"排序","prop":"orderNum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('Remark',{attrs:{"row":row,"url":"/admin/Activity/speakerOrder","rowKey":"orderNum"},on:{"getlist":_vm.getlist}})]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"createTime"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.edit(row)}}},[_vm._v("编辑")]),_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.del(row.id)}}},[_vm._v("删除")])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.search.page,"background":"","page-sizes":[3, 5, 10, 15],"page-size":_vm.search.pageSize,"layout":"total, prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }